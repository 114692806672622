import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";

let config = {
    apiKey: "AIzaSyBFNL4lExr1Tz8rIcN-t0fihtUi_u1jEsY",
    authDomain: "chithu-pickup.firebaseapp.com",
    projectId: "chithu-pickup",
    storageBucket: "chithu-pickup.appspot.com",
    messagingSenderId: "722044365116",
    appId: "1:722044365116:web:c771e136596e2835f15639",
    databaseURL: "https://chithu-pickup-default-rtdb.europe-west1.firebasedatabase.app"
};



const app = initializeApp(config);
export const db = getDatabase(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
