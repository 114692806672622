import AdminLogin from '../components/AdminLogin';
import AdminDashboard from '../components/AdminDashboard';
import { useCurrentUserLocation } from '../hooks/firebase';

//admin page
const Admin = () => {
    const currentUserLocation = useCurrentUserLocation(); //if you get a user location, the user is logged in
    return (
        <div className="w-full h-full flex justify-center items-center flex-nowrap">
            {!currentUserLocation && <AdminLogin ></AdminLogin>}
            {currentUserLocation && <AdminDashboard location={currentUserLocation}></AdminDashboard>}
        </div>
    );
};
  
export default Admin;