interface IndexProps {
    nav: JSX.Element[]
}

//index page
const Index = (props: IndexProps) => {
    return (
        <div className="w-full h-full flex justify-center items-center flex-col">
            <h1 className="text-8xl mb-10">Chi Thu Pickup System</h1>
            <p className="text-3xl mb-5">Restaurant auswählen: </p>
            {props.nav}
        </div>
    );
};
  
export default Index;