import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DisplayOrders from "./pages/DisplayOrders";
import Admin from "./pages/Admin";
import Index from "./pages/Index";
import Button from './atoms/Button';

function App() {
    //add all locations; doesn't work dynamically for security reasons https://stackoverflow.com/questions/65468562/ionic-firebase-how-to-get-all-user-emails-from-firebase-authentication
    const locations = ['pestalozzi', 'morassi', 'fraunhofer', 'leopold'];

    //create routes and navbuttons on index
    const displayOrderRoutes = locations.map(location => <Route key={location} path={location} element={<DisplayOrders location={location}/>}/>)
    const navigationButtons = locations.map(location => <a key={location} href={`/${location}`}><Button value={location} classNames='capitalize'></Button></a>);

  return (
    <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route index element={<Index nav={navigationButtons}></Index>}/>
                    <Route path="admin" element={<Admin/>} />
                    {displayOrderRoutes}
                </Routes>
            </BrowserRouter>
    </div>
  );
}

export default App;