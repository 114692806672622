import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from 'react';
import Button from '../atoms/Button';
import Label from '../atoms/Label';
import InputField from '../atoms/InputField';

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    //log user in with email and password
    const handleLogin = (event: React.SyntheticEvent) => {
        event.preventDefault();
        signInWithEmailAndPassword(auth, email, password);
        setEmail('');
        setPassword('');
        return;
      };

        //todo form component?
        return (
        <div>
            <form onSubmit={handleLogin} className="bg-white shadow-md rounded px-8 pt-6 pb-8 w-3/4 md:w-96">
                <div className="mb-4">
                    <Label value="Login"></Label>
                    <InputField type="text" name="email" value={email} placeholder="E-Mail" onChange={(event) => { setEmail(event.target.value); }}></InputField>
                    <InputField type="password" name="password" value={password} placeholder="Passwort" onChange={(event) => { setPassword(event.target.value); }}></InputField>
                    <Button value="Einloggen" type="submit"></Button>
                </div>
            </form>
        </div>
    );
};
  
  export default AdminLogin;