import OrdersList from '../types/OrdersList';
import List from './List';
import { db } from "../firebaseConfig"
import { ref, update } from "firebase/database";

//create array of objects, which contains all orders in preparation
const OrdersInPreparationList = (props: OrdersList) => {
    const ordersInPreparation = Object.entries(props.data)
    .filter(([key, val]) => (!val.isReadyForPickup))
    .map(([key, val]) => {
        return {
            number: key,
            isReadyForPickup: val.isReadyForPickup,
            timeStamp: val.timeStamp,
        };
    })
    .sort((a: any, b: any) => a.timeStamp - b.timeStamp);

    //change isReadyForPickup to true for specific order
    const setOrderReadyForPickup = (orderNumber: string) => {
        if (!orderNumber) return;
        update(ref(db, props.firebasePath + '/' + orderNumber), ({
            isReadyForPickup: true,
        }))
        return;
    }

  return (
    <div>
      <List title={'Wird zubereitet'} orders={ordersInPreparation} isShownOnAdminDashboard={props.isShownOnAdminDashboard} listItemButtonOnClick={setOrderReadyForPickup} buttonText="Abholbereit" isOrderInPreparation={true}></List>
    </div>
  );
}

export default OrdersInPreparationList;