import Orders from '../types/Orders';

interface ListProps {
    title: string,
    orders?: Array<Orders>,
    isShownOnAdminDashboard?: boolean,
    listItemButtonOnClick: (orderNumber: string) => void,
    buttonText?: string,
    isOrderInPreparation?: boolean
  }

const List = (props: ListProps) => {
    let itemList=[];
    
    //add zero to numbers with only one character // 1 => 01
    const formatNumber = (number: string) => {
        if(number.length !== 1) return number;
        return "0" + number;
    }

    //add css classes depending where the list item is used
    let listItemClasses = 'p-4 font-bold rounded border-4 border-white';
    if (!props.isShownOnAdminDashboard) listItemClasses += ' md:p-8 lg:p-12 md:text-9xl';
    if (!props.isShownOnAdminDashboard && props.isOrderInPreparation) listItemClasses += ' opacity-60';
    if (!props.isShownOnAdminDashboard && !props.isOrderInPreparation) listItemClasses += ' bg-white';
    if (props.isShownOnAdminDashboard) listItemClasses += ' md:p-7 bg-white font-bold rounded focus:outline-none focus:shadow-outline lg:w-60 group hover:bg-[color:var(--text-color)] hover:text-white cursor-pointer text-4xl';

    //create html list
    if (props.orders) {
        props.orders.map((order) => itemList.push(
            <li key={order.number} className={listItemClasses} onClick={props.isShownOnAdminDashboard ? () => props.listItemButtonOnClick(order.number) : undefined}>
                {formatNumber(order.number)}
                {props.isShownOnAdminDashboard && <span className="hidden lg:block opacity-0 group-hover:opacity-100 text-base p-3" >{props.buttonText}</span>}
            </li>
        ))
    }

    //when there aren't any orders, create a empty list
    if (!props.orders || props.orders.length === 0) {
        itemList.push(<li key="empty"></li>);
    }

  return (
    <div className="mb-10">
      <h2 className='text-5xl md:text-6xl lg:text-8xl mb-8 text-center'>{props.title}</h2>
      <ul className='flex gap-10 justify-center lg:justify-start align-start flex-wrap'>
        {itemList}
      </ul>
    </div>
  );
}

export default List;