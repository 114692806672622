import { useState } from 'react';
import OrdersInPreparationList from './OrdersInPreparationList';
import OrdersReadyForPickUpList from './OrdersReadyForPickupList';
import { db } from "../firebaseConfig"
import { set, ref } from "firebase/database";
import { signOut } from "firebase/auth";
import { auth } from '../firebaseConfig';
import Button from '../atoms/Button';
import Label from '../atoms/Label';
import InputField from '../atoms/InputField';
import { useFirebaseData } from '../hooks/firebase'

interface AdminDashboardProps {
    location: string, 
}

const AdminDashboard = (props: AdminDashboardProps) => {

    const [formStylingClass, setFormStylingClass] = useState<string>('hidden');
    const [popupClasses, setPopupClasses] = useState<string>('hidden');
    const [orderNumber, setOrderNumber] = useState<number | undefined | string>(0);

    const firebasePath = 'orders/' + props.location;

    //when order form is submitted, add it to firebase and remove popups
    const handleOrderNumberSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        addOrderToFirebase(orderNumber);
        handlePopupsForForm();
        setOrderNumber('');
        return;
    }

    const addOrderToFirebase = (number: number | undefined | string) => {
        if (number) {
            set(ref(db, firebasePath + '/' + number), {
                isReadyForPickup: false,
                timeStamp: Date.now(),
            });
        }
        return;
    }

    const handleSignOut = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        signOut(auth);
        return;
    }

    //triggers the popups for the form
    const handlePopupsForForm = (event?: React.MouseEvent<HTMLElement>) => {
        if (event) event.preventDefault();
        setOrderNumber('');
        if(formStylingClass === "hidden" ) {
            setFormStylingClass("block top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2");
            setPopupClasses("absolute block w-full h-full top-0 left-0");
        } else {
            setFormStylingClass("hidden");
            setPopupClasses("hidden");
        }
        return;
    }

    //when the value of the input field for a new order number is changed
    const handleOrderNumberInputChange = (inputValue: string) => {
        let inputValueNumber: number = +inputValue;
        if(isNaN(inputValueNumber) || inputValueNumber === undefined || inputValueNumber === null || inputValueNumber === 0 ) return setOrderNumber('');
        return setOrderNumber(inputValueNumber);
    }

    const firebaseData = useFirebaseData(props.location);

    //todo form component?
    return(
        <div className="h-full w-full">
            <div className='text-2xl md:text-4xl text-center lg:text-left text-white bg-black px-10 py-5'>
                <h1 className=''>Admin <span className="capitalize">{props.location}</span>straße </h1>
                <div className={`bg-white opacity-60 z-10 ${popupClasses}`}></div>
            </div>
            <form onSubmit={handleOrderNumberSubmit} className={`bg-white shadow-md rounded px-8 pt-6 pb-8 w-3/4 md:w-96 m-auto mt-5 mb-5 absolute z-20 ${formStylingClass}`}>
                <div className="mb-4">
                    <Label value="Bestellnummer:"></Label>
                    <InputField inputMode="numeric" pattern="[0-9]*" type="numeric"  name="orderNumber" placeholder="Bestellnummer eingeben" value={orderNumber} onChange={(e) => handleOrderNumberInputChange(e.target.value)}></InputField>
                    <Button value="Bestätigen" type="submit"></Button>
                    <Button value="Abbrechen" type="button" onClick={handlePopupsForForm}></Button>
                </div>
            </form>
            <Button value="Bestellnummer eingeben" onClick={handlePopupsForForm} classNames="lg:absolute lg:top-5 lg:right-10 mt-5 lg:mt-0"></Button>
            <div className="p-5 lg:px-10">
                {firebaseData && <OrdersInPreparationList isShownOnAdminDashboard={true} data={firebaseData} firebasePath={firebasePath}></OrdersInPreparationList>}
            </div>
            <div className="p-5 lg:px-10">
                {firebaseData && <OrdersReadyForPickUpList isShownOnAdminDashboard={true} data={firebaseData} firebasePath={firebasePath}></OrdersReadyForPickUpList>}
            </div>
            <Button value="Ausloggen" onClick={handleSignOut}></Button>
        </div>
    );
}

export default AdminDashboard;