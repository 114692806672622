import OrdersReadyForPickUpList from '../components/OrdersReadyForPickupList';
import GifAnimation from '../atoms/GifAnimation';
import { useFirebaseData } from '../hooks/firebase'

interface DisplayOrdersProps {
    location: string,
}

//index page => displays all orders
const DisplayOrders = (props: DisplayOrdersProps) => {
    const firebaseData = useFirebaseData(props.location);
    const firebasePath = 'orders/' + props.location;

    return (
    <div className="flex h-full gap-24 overflow-hidden">
        <div className="w-2/3 flex flex-col p-10 m-auto mt-0">
            {firebaseData && <OrdersReadyForPickUpList isShownOnAdminDashboard={false} data={firebaseData} firebasePath={firebasePath}></OrdersReadyForPickUpList>}
        </div>
        {firebaseData && <GifAnimation></GifAnimation>}
    </div>
    ); 
};
  
export default DisplayOrders;