import OrdersList from '../types/OrdersList';
import List from './List';
import { db } from "../firebaseConfig"
import { set, ref } from "firebase/database";

const OrdersReadyForPickUpList = (props: OrdersList) => {
    //create array of objects, which contains all orders which are ready for pickup
    const ordersReadyForPickUp = Object.entries(props.data)
    .filter(([key, val]) => (val.isReadyForPickup))
    .map(([key, val]) => {
        return {
            number: key,
            isReadyForPickup: val.isReadyForPickup,
            timeStamp: val.timeStamp
        };
    })
    .sort((a: any, b: any) => a.timeStamp - b.timeStamp);

    const removeOrderFromDatabase = (orderNumber: string) => {
        if (!orderNumber) return;
        set(ref(db, props.firebasePath + '/' + orderNumber), null);
        return;
    }

  return (
    <div>
      <List title={'Abholbereit'} orders={ordersReadyForPickUp} isShownOnAdminDashboard={props.isShownOnAdminDashboard} listItemButtonOnClick={removeOrderFromDatabase} buttonText="Abgeholt"></List>
    </div>
  );
}

export default OrdersReadyForPickUpList;