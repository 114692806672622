interface ButtonProps {
    value: string
    type?: string
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    classNames?: string
}

const Button = (props: ButtonProps) => {
    let classNames = "mb-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline cursor-pointer mx-4";
    let type = "button";
    if (props.type) type = props.type;
    if (props.classNames) classNames += " " + props.classNames;

    return (
        <input className={classNames} type={type} value={props.value} onClick={props.onClick} />
    )
}

export default Button;