import { ref, onValue } from "firebase/database";
import { useEffect, useState } from 'react';
import Orders from "../types/Orders";
import { db } from '../firebaseConfig';
import { auth } from '../firebaseConfig';
import { onAuthStateChanged } from '@firebase/auth';

export const useCurrentUserLocation = () => {
    const [currentUserLocation, setCurrentUserLocation] = useState<string | null | undefined>(null);
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user?.email) setCurrentUserLocation(user?.email.slice(0, user?.email.indexOf('@')));   
            if (!user?.email) setCurrentUserLocation(undefined);
        });
    })
    return currentUserLocation;
}

export const useFirebaseData = (location: string) => {
    const [firebaseData, setFirebaseData] = useState<Array<Orders>>();
    useEffect(() => {
        onValue(ref(db, "orders/" + location), (snapshot: { val: () => any; exists: () => any; }) => {
            const data:Array<Orders> = snapshot.val();
            if (snapshot.exists()) {
                setFirebaseData(data);
            }
            if (!snapshot.exists()) {
                setFirebaseData([]);
            }
        });
    }, [location]);
    return firebaseData;
}