// import { useRef } from "react";

const GifAnimation = () => {

    //!chi thu apes code
    // //doesn't work dynamically https://stackoverflow.com/questions/70609856/get-a-list-of-files-in-a-react-web-application
    // const gifFileNames = ['liana', 'wheel'];
    // //counts the renders for the component; everytime it gets newly rendered, the next gif needs to be shown.
    // const renderCounter  = useRef(0);
    // //create dynamic path
    // const gifFilePath = 'gifs/' + gifFileNames[renderCounter.current] + '.gif';
    // //render counter ++
    // renderCounter.current = renderCounter.current + 1;
    // //if counter is longer than array, set it to 0 to beginn again
    // if (renderCounter.current === gifFileNames.length) renderCounter.current = 0;


    //! chi thu queue code
    const gifFilePath = 'gifs/chi_thu.gif';
    return (
        <div className="absolute bottom-10 right-10 h-2/5 mix-blend-darken rounded">
            <img src={gifFilePath} alt="Chi Thu Affe" className="w-auto h-full" />
        </div>   
    )

}

export default GifAnimation;